<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <base-card heading="Daily Opening Stock">
            <template>
              <v-data-table :headers="headers" :items="data" :items-per-page="10">
                <template v-slot:[`item.date`]="{ item }">
                  {{ formatDate(item.date, false) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu transition="slide-x-transition" bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon right v-bind="attrs" v-on="on"> mdi-dots-vertical </v-icon>
                    </template>
                    <v-list>
                      <v-list-item link @click="editItem(item)">
                        <v-list-item-title class="caption">View</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="editItem(item)">
                        <v-list-item-title class="caption">Edit</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </template>
          </base-card>
        </v-col>
        <v-col>
          <base-card heading="Upload Excel-sheet">
            <v-radio-group v-model="stockType" row>
              <v-radio label="Shop" value="SHOP"></v-radio>
              <v-radio label="Warehouse" value="WAREHOUSE"></v-radio>
            </v-radio-group>
            <v-row class="align-center">
              <v-col cols="12" sm="6" md="4" class="mb-5">
                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Select Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date"></v-date-picker>
                </v-menu>
              </v-col>
              <v-file-input prepend-inner-icon @change="setExcelFile" label="Select file for upload" outlined dense />
            </v-row>
            <v-btn text color="primary" @click="uploadFile"> Upload </v-btn>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseCard from "../../components/BaseCard.vue";
import { getAll, uploadFile, addBranchDailyOpeningStock } from "./lib";
import { DateService } from "../../common/DateService";
export default {
  components: { BaseCard },
  name: "DailyOpeningStock",
  data() {
    return {
      data: [],
      excelFile: null,
      date: null,
      stockType: "SHOP",
      headers: [
        { text: "Date", value: "date" },
        { text: "Branch", value: "branch.code" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  methods: {
    formatDate: DateService.formatDate,
    async initialize() {
      try {
        this.data = await getAll();
      } catch (error) {
        console.log(error);
      }
    },
    async setExcelFile(file) {
      this.excelFile = file;
    },
    async uploadFile() {
      if (!this.excelFile) {
        this.$notify({
          title: "Error",
          text: `Please select a file for uploading`,
          type: "error",
        });
        return;
      }

      try {
        await this.$store.dispatch("setLoading", true);

        const fileLink = await uploadFile(this.excelFile);

        const res = await addBranchDailyOpeningStock(this.date, this.stockType, fileLink);

        console.log(res.data);

        if (res.data.statusCode == 200) {
          this.$notify({
            title: "Success",
            text: "Opening stock has been uploaded successfully",
            type: "success",
          });
          this.excelFile = null;
          await this.initialize();
        }
        await this.$store.dispatch("setLoading", false);
      } catch (error) {
        await this.$store.dispatch("setLoading", false);
        this.$notify({
          title: "Error",
          text: error.response.data.message,
          type: "error",
        });
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.initialize();
    await this.$store.dispatch("setLoading", false);
  },
};
</script>

<style></style>
