import axios from "axios";

async function getAll() {
  try {
    let response = await axios.get("daily-opening-stock/findAll");
    return response.data;
  } catch (error) {
    return error;
  }
}

async function uploadFile(data) {
  let formData = new FormData();

  formData.append("file", data, data.name);
  formData.append("path", "/branch_opening_stocks");

  const res = await axios.post(`storage/uploadFile`, formData);

  return res.data;
}

async function addBranchDailyOpeningStock(date, stockType, fileLink) {
  const body = {
    date,
    stockType,
    fileLink,
  };

  return await axios.post("daily-opening-stock", body);
}

export { getAll, uploadFile, addBranchDailyOpeningStock };
