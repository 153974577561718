var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',[_c('base-card',{attrs:{"heading":"Daily Opening Stock"}},[[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":10},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date, false))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',{staticClass:"caption"},[_vm._v("View")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',{staticClass:"caption"},[_vm._v("Edit")])],1)],1)],1)]}}],null,true)})]],2)],1),_c('v-col',[_c('base-card',{attrs:{"heading":"Upload Excel-sheet"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.stockType),callback:function ($$v) {_vm.stockType=$$v},expression:"stockType"}},[_c('v-radio',{attrs:{"label":"Shop","value":"SHOP"}}),_c('v-radio',{attrs:{"label":"Warehouse","value":"WAREHOUSE"}})],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Date","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-file-input',{attrs:{"prepend-inner-icon":"","label":"Select file for upload","outlined":"","dense":""},on:{"change":_vm.setExcelFile}})],1),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.uploadFile}},[_vm._v(" Upload ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }